#info-app {
    display: flex;
    flex-direction: column;

    align-items: center;

    background: linear-gradient(-45deg, rgb(0, 132, 255) 0%, var(--info) 60%);
    padding: 15px;
}

.info-title {
    margin-top: 40px;
    margin-bottom: 15px;
}

.info-item-wrapper {
    display: flex;

    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: auto;
    padding: 10px;
    margin: 5px;
    text-shadow: 0 0 2.5px rgba(255, 255, 255, 0.678);

    font-size: 15px;
 
    border-radius: 1.5vh;
    
    background: rgba(0, 0, 0, 0.596);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.144);
    border: 1px solid rgba(255, 255, 255, 0);
    
    
}

.info-actions-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 20%;
    padding: 15px;
    padding-top: 25px;
    margin: 5px;

    font-size: 15px;
 
    border-radius: 0.5vh;
    
    background: rgba(0, 0, 0, 0);
}

.action-wrapper {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    color: rgba(255, 255, 255, 0);



    transition: all 0.2s ease-in-out;
}

.action-wrapper:hover {
    cursor: pointer;
    color: white;
    font-weight: bold;

    text-shadow: 0 0 2.5px rgb(0, 0, 0);
    scale: 1.05;
}

.info-action {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 50px;
    height: 50px;

    margin-bottom: 5px;

    color: white;

    border-radius: 50%;

    background-color: rgba(0, 0, 0, 0.452);
    transition: all 0.2s ease-in-out;

}

.info-action:hover {
    background-color: rgba(0, 0, 0, 0.596);
    border: 2px solid white;
}