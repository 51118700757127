#phone-app {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    background: linear-gradient(to bottom, rgb(4, 167, 53), rgb(4, 180, 151));
}


.dial-pad {
    display: flex;

    flex-direction: column;
    
    justify-content: center;
    align-items: center;
    padding: 10px;

    width: 100%;

    background-color: rgba(0, 128, 0, 0);
}

.number-input {
    width: 100%;
    height: 50px;
    font-size: 27.5px;
    font-weight: bold;
    text-align: center;
    background: rgba(161, 6, 6, 0);
    color: white;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.37);
    border: none;
    border-radius: 5px;
    margin-bottom: 15px;

    justify-content: center;
    align-items: center;
}

.dial-pad-row {
    display: flex;
    width: 100%;
    
    background-color: rgba(255, 0, 0, 0);
    flex-direction: row;

    justify-content: space-between;
}

#dial-pad-row-bottom {
    justify-content: flex-end;

}

.dial-pad-button {
    width: 60px;
    height: 60px;
    margin: 10px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.363);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: white;
    cursor: pointer;
}

#dial-pad-button-call {
    background: rgb(4, 167, 53);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    color: white;
}
#dial-pad-button-call:hover, #dial-pad-button-delete:hover, #dial-pad-button-recent:hover {
    scale: 1.05;
}


#dial-pad-button-delete, #dial-pad-button-recent {
    background: #ff000000;
    color: white;
}

.dial-pad-button:hover {
    background: rgba(0, 0, 0, 0.5);
}


.recent-calls-wrapper {
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 100%;
    height: 85%;
    padding: 15px;
    background: rgba(0, 0, 0, 0.938);
}

.recent-calls-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: rgba(255, 255, 255, 0.514);
    margin-bottom: 15px;
    border-radius: 0.25vh;
    border-bottom: 2px solid rgba(255, 255, 255, 0.74);
}

.close-recent-calls {
    cursor: pointer;
    text-shadow: 0 0 2.5px rgb(0, 0, 0);
}

.close-recent-calls:hover {
    color: rgba(255, 0, 0, 1);
    scale: 1.05;
}

.recent-calls-list-wrapper {
    display: flex;
    flex-direction: column;
    
    width: 100%;
    height: auto;

    padding: 0px;
    background: rgba(255, 255, 255, 0);
    border-radius: 0.25vh;


    overflow-y: auto;
}

.recent-call-item {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    min-height: 75px;

    font-size: 15px;

    padding: 10px;
    padding-right: 15px;
    background: rgba(255, 255, 255, 0.404);
    margin-bottom: 5px;
    border-radius: 0.25vh;

}

.recent-call-item-name {
    display: flex;
    margin-bottom: 5px;

    font-size: 15px;
}

.recent-call-item-date {
    display: flex;

    font-size: 12px;

    color: rgba(255, 255, 255, 0.705);

    gap: 10px;

    justify-content: center;
    align-items: center;
}

.recent-call-action:hover {
    color: rgba(255, 255, 255, 0.699);
    scale: 1.05;
    cursor: pointer;
}