#gang-app {
    box-shadow: inset 0 0 150px 0px rgba(0, 0, 0, 0.644);
    display: flex;
    flex-direction: column;

    background-image: url('http://imageshack.com/a/img924/646/IsRhPJ.png');
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.gang-name-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    width: 90%;

    font-size: 25px;
    padding: 5px;
    border-radius: 0.5vh;

    text-align: center;
    /* text-transform: uppercase; */

    background-color: rgb(31, 31, 31);
    box-shadow: inset 0 0 15px 0px rgba(0, 0, 0, 0.644);
    border: 1px solid rgba(255, 255, 255, 0.507);

    text-shadow: 0 0 1px rgba(0, 0, 0, 0.815);
}

.gang-color-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    background-color: rgb(31, 31, 31);
    box-shadow: inset 0 0 15px 0px rgba(0, 0, 0, 0.644);
    padding: 10px;
    border-radius: 0.5vh;
    border: 1px solid rgba(255, 255, 255, 0.507);

    width: 90%;
}

.copy-color-button:hover {
    cursor: pointer;
    scale: 1.1;
}

.gang-management-wrapper {
    display: flex;

    width: 100%;
    padding: 10px;

    justify-content: space-between;
}

.gang-manage-button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 50%;
    margin: 2.5px;
    padding: 5px;

    font-size: 12.5px;
    font-weight: bold;

    border-radius: 0.5vh;

    background-color: rgb(31, 31, 31);
    box-shadow: inset 0 0 15px 0px rgba(0, 0, 0, 0.644);
    border: 1px solid rgba(255, 255, 255, 0.507);

    

}

.gang-manage-button:hover {
    cursor: pointer;
    scale: 1.05;
}

.gang-members-wrapper {
    margin-top: 5px;


    display: flex;
    flex-direction: column;

    width: 100%;

    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;

    border-bottom: 2px solid rgba(255, 255, 255, 0.507);

    height: 70%;

    background-color: rgba(0, 0, 0, 0.452);

    overflow-y: auto;
}

.gang-member-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;

    width: 100%;

    font-size: 12.5px;
    padding: 10px;
    border-radius: 0.5vh;

    background-color: rgb(24, 24, 24);
    box-shadow: inset 0 0 15px 0px rgba(0, 0, 0, 0.644);
    border: 1px solid rgba(255, 255, 255, 0.507);
}

.member-remove-button:hover {
    cursor: pointer;
    scale: 1.05;
}

.cid-input {
    position: absolute;
    display: flex;

    flex-direction: column;

    justify-content: center;
    align-items: center;

    width: 75%;
    height: auto;

    border-radius: 0.5vh;

    padding: 25px;

    background-color: rgba(0, 0, 0, 0.904);

    z-index: 1000;
}

#cid-input-text {
    display: flex;

    width: 100%;

    border: 1px solid #ccc;
    
    padding: 5px;
    font-size: 14px;
    margin-top: 15px;
}

.invite-buttons-wrapper {
    display: flex;
    justify-content: space-between;

    width: 100%;
    margin-top: 15px;

}

.gang-invite-submit, .gang-invite-cancel {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    margin: 5px;
    padding: 2.5px;
    border-radius: 0.5vh;

    background-color: rgb(23, 110, 6);
    box-shadow: inset 0 0 15px 0px rgba(0, 0, 0, 0.644);
    border: 1px solid rgba(255, 255, 255, 0.507);

    font-size: 15px;
}

.gang-invite-cancel {
    background-color: rgb(110, 6, 6);
}

.gang-invite-submit:hover, .gang-invite-cancel:hover {
    cursor: pointer;
    opacity: 0.8;
}

.invites-title {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 90%;

    font-size: 20px;
    padding: 5px;
    border-radius: 0.5vh;

    margin-top: 10px;
    margin-bottom: 10px;

    text-align: center;
    /* text-transform: uppercase; */

    background-color: rgb(31, 31, 31);
    box-shadow: inset 0 0 15px 0px rgba(0, 0, 0, 0.644);
    border: 1px solid rgba(255, 255, 255, 0.507);

    text-shadow: 0 0 1px rgba(0, 0, 0, 0.815);
}

.invites-wrapper {
    display: flex;
    flex-direction: column;

    width: 90%;

    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;

    border-bottom: 2px solid rgba(255, 255, 255, 0.507);

    height: 100%;

    background-color: rgba(0, 0, 0, 0.452);

    overflow-y: auto;
}

.invite-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;

    width: 100%;

    font-size: 15px;
    padding: 10px;
    border-radius: 0.5vh;

    background-color: rgb(24, 24, 24);
    box-shadow: inset 0 0 15px 0px rgba(0, 0, 0, 0.644);
    border: 1px solid rgba(255, 255, 255, 0.507);
}

.invite-accept {
    color: green;
    margin-right: 10px;
}

.invite-accept:hover {
    cursor: pointer;
    opacity: 0.8;
}

.invite-decline {
    color: red;
}

.invite-decline:hover {
    cursor: pointer;
    opacity: 0.8;
}

.member-rank:hover {
    cursor: pointer;
    opacity: 0.8;
}