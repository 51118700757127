
#messages-app {
    background: linear-gradient(180deg, rgb(0, 174, 255) 0%, rgb(25, 93, 219) 60%);
}

.messages-header {
    display: flex;
    width: 100%;
    height: auto;
    background-color: rgba(0, 0, 0, 0.747);

    border-bottom: 2px solid rgba(255, 255, 255, 0.637);

    padding: 15px;

    align-items: center;
    justify-content: space-between;

    font-size: 17.5px;
}

.messages-title {
    display: flex;
    position: relative;

    width: 100%;
    justify-content: space-between;
}

.new-convo:hover {
    color: rgb(0, 140, 255);
    cursor: pointer;
}

.back-arrow {
    display: flex;
    position: relative;

    background-color: rgba(255, 255, 255, 0.322);
    text-shadow: 0 0 2.5px rgba(0, 0, 0, 0.5);
    padding: 10px;
    margin-right: 40px;
    font-size: 15px;

    width: 35px;
    height: 35px;

    border-radius: 0.5vh;

    justify-content: center;
    align-items: center;
}

.back-arrow:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
}

.conversations {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
  
    background-color: rgba(218, 23, 23, 0);

    padding: 5px;

    overflow-y: scroll;
}

.conversation-item {
    display: flex;

    width: 100%;
    height: auto;

    align-items: center;
    justify-content: space-between;
  
    background-color: rgba(0, 0, 0, 0.63);
    box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.377);

    border: 1px solid rgba(255, 255, 255, 0.336);

    padding: 20px;
    font-size: 15px;
    border-radius: 0.5vh;

    margin-bottom: 5px;
}

.conversation-item:hover {
    opacity: 0.8;
    cursor: pointer;
}

.conversation-read {
    color: rgb(0, 153, 255);
    font-size: 15px;
    margin-right: 5px;
}

.conversation-title {
    color: white;
    font-size: 15px;

    margin-right: 10px;
}

.conversation-last-message {
    color: rgba(255, 255, 255, 0.575);
    font-size: 12.5px;

    background-color: rgba(255, 255, 255, 0);

    width: 35%;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.conversation-wrapper {
    display: flex;
    position: relative;

    width: 100%;
    height: 100%;

    background-color: rgba(11, 216, 28, 0);

    flex-direction: column;

}

.conversation-content {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 390px;

    background-color: rgba(228, 4, 4, 0);

    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;

    overflow-y: scroll;
}

.message-me {
    display: flex;
    flex-direction: column;

    width: 70%;

    margin-top: 10px;

    justify-self: flex-end !important;
    align-self: flex-end !important;
    font-size: 12.5px;

    background-color: rgb(255, 255, 255);
    color: rgba(26, 26, 26, 0.74);
    font-weight: bold;

    left: 0;

    padding: 15px;
    border-radius: 0.5vh;
}

.message {
    display: flex;
    position: relative;
    flex-direction: column;

    margin-top: 10px;

    font-size: 12.5px;

    width: 70%;

    right: 0;
    padding: 15px;
    border-radius: 0.5vh;

    background-color: rgba(0, 0, 0, 0.562);
    

}

.message-input {
    display: flex;
    position: relative;

    width: 100%;
    max-height: 13%;


    border-top: 2px solid rgba(0, 0, 0, 0.692);
    background-color: rgba(0, 0, 0, 0.555);

    padding: 10px;

    align-items: center;
    justify-content: space-between;

    
}

.message-input textarea {
    width: 85%;
    max-height: 100%;

    padding: 5px;

    font-size: 12.5px;

    border-radius: 0.5vh;
    border: none;

    background-color: rgba(0, 0, 0, 0.623);
    color: white;

    resize: none;
}

.message-input  textarea:focus {
    outline: none;
}

.message-input textPath {
    color: white;
}
  
.message-input textarea::placeholder {
    color: rgba(255, 255, 255, 0.685);
}

.send-message-button {
    display: flex;
    position: relative;

    width: 15%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0);
    color: rgb(255, 255, 255);
    font-size: 20px;

    align-items: center;
    justify-content: center;

    border-radius: 0.5vh;
}

.send-message-button:hover {
    opacity: 0.8;
    cursor: pointer;
}

.message-context-wrapper {
    display: flex;
    position: absolute;
    
    align-items: center;

    width: 100%;
    height: auto;

    top: 17.5%;
    padding: 15px;

    background-color: rgb(17, 17, 17);

    color: rgb(255, 255, 255);

    flex-direction: column;

    z-index: 100;
}

.message-context-header {
    display: flex;
    position: relative;

    justify-content: center;

    width: 100%;
    height: auto;

    margin-bottom: 25px;
    padding: 5px;

    background-color: rgba(255, 255, 255, 0.151);
    border-radius: 0.5vh;

}

.number-input-wrapper {
    display: flex;

    justify-content: space-between;
    align-items: center;

    gap: 15px;

    height: 30px;
}

.new-message-number-input {
    display: flex;
    position: relative;

    width: 100%;
    height: 100%;

    background-color: rgba(255, 255, 255, 0.801);
    border: none;

    align-items: center;
    justify-content: space-between;

    padding: 10px;
}

.new-message-number-input:focus {
    outline: none;
}

.contacts-button {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    background-color: rgba(8, 133, 46, 0.699);
    color: white;
    border-radius: 0.5vh;
    border: 2px solid rgba(0, 0, 0, 0.342);
    box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.377);
    font-size: 12.5px;

    padding: 5px 10px;
}

.contacts-button:hover {
    background-color: rgba(8, 133, 46, 0.8);
    cursor: pointer;
}

.contacts-dropdown {
    display: flex;

    width: 100%;

    margin-top: 15px;

    justify-content: center;
    align-items: center;

    border-top: 1px solid rgba(255, 255, 255, 0.637);

    padding-top: 15px;

    gap: 10px;

    margin-bottom: 15px;
}

.contacts-dropdown-select {
    display: flex;
    position: relative;

    width: 100%;

    background-color: rgb(33, 34, 34);
    color: white;
    border: 2px solid rgba(0, 0, 0, 0.342);
    border-radius: 0.5vh;

    font-size: 12.5px;

    align-items: center;
    justify-content: center;

    padding: 5px;
}

.start-new-convo:hover {
    color: rgb(0, 140, 255);
    cursor: pointer;
    scale: 1.05;
}

.close-context-message {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    background-color: rgba(133, 8, 8, 0.699);
    color: white;
    border-radius: 0.5vh;
    border: 2px solid rgba(0, 0, 0, 0.342);
    box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.377);
    font-size: 12.5px;

    padding: 5px 10px;

    margin-top: 15px;
}

.close-context-message:hover {
    background-color: rgba(133, 8, 8, 0.8);
    cursor: pointer;
}
