
#contacts-app {
    background: radial-gradient(circle, rgb(228, 180, 47) 0%, rgb(46, 46, 46) 100%);

    justify-content: center;
    align-items: center;
}

.contacts-top {
    display: flex;

    width: 100%;
    height: 10%;

    padding: 15px;

    align-items: center;
    justify-content: space-between;

    
    font-size: 17.5px;
    font-weight: bold;
    color: white;

    padding-bottom: 20px;

    border-bottom: 2px solid rgba(255, 255, 255, 0.582);
}

.contact-add {
    display: flex;

    align-items: center;
    justify-content: center;

    width: 25px;
    height: 25px;

    font-size: 20px;
    margin-left: 15px;
}

.contact-add:hover {
    cursor: pointer;
    color: green;
}

.contacts-bottom {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 90%;

    color: white;

    background-color: rgba(0, 0, 0, 0.432);
    box-shadow: inset 0 0 15px 0px rgba(0, 0, 0, 0.644);

    overflow-y: scroll;

}

.contact-wrapper {
    display: flex;

    align-items: center;
    justify-content: center;

    width: 100%;
    height: 17.5%;

    padding: 15px;

    color: white;

    background-color: rgba(0, 0, 0, 0.432);
    box-shadow: inset 0 0 15px 0px rgba(0, 0, 0, 0.644);
    border-bottom: 2px solid rgba(255, 255, 255, 0.582);
}

.contact-icon {
    display: flex;

    align-items: center;
    justify-content: center;

    width:  40px;
    height: 40px;

    font-size: 15px;

    border-radius: 5vh;

    color: rgba(0, 0, 0, 0.671);

    background-color: rgba(255, 255, 255, 0.808);
    box-shadow: inset 0 0 10px 0px rgba(0, 0, 0, 0.644);
}

.contact-icon:hover {
    cursor: pointer;
    scale: 1.05;
}

.contact-delete {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 25%;
    height: 100%;
    font-size: 22.5px;
    color: red;
}

.contact-delete:hover {
    cursor: pointer;
    opacity: 0.8;
    font-size: 25px;

}

.contact-info {
    display: flex;
    flex-direction: column;
    
    justify-content: center;

    width: 65%;
    height: 100%;

    font-size: 15px;
    padding-left: 15px;
}

.contact-number {
    font-size: 12.5px;
    color: rgba(255, 255, 255, 0.651);
}

.contact-options {
    display: flex;

    align-items: center;
    justify-content: center;

    width: 20%;
    height: 100%;

    font-size: 20px;
}

.contact-option {
    margin: 7.5px;
    font-size: 15px;
}

.contact-option:hover {
    cursor: pointer;
    color: green;
}

.add-contact-wrapper, .edit-contact-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;

    justify-self: center;
    align-self: center;


    align-items: space-between;
    justify-content: space-between;

    width: 85%;

    padding: 15px;

    background-color:rgb(129, 129, 129);
    box-shadow: inset 0 0 15px 0px rgba(0, 0, 0, 0.644);
    border-radius: 0.5vh;


    z-index: 1;
}

.add-contact-title {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 20%;

    font-size: 15px;
    color: white;

    margin-bottom: 15px;

}

.contact-input {
    display: flex;

    align-items: center;
    justify-content: center;

    width: 100%;

    background-color: rgba(0, 0, 0, 0.432);
    box-shadow: inset 0 0 15px 0px rgba(0, 0, 0, 0.644);
    border: none;

    padding: 5px;
    margin-bottom: 5px;

    border-radius: 0.5vh;

    color: white;

    
}

.contact-input::placeholder {
    color: rgba(255, 255, 255, 0.753);
}

.contact-input::-webkit-outer-spin-button,
.contact-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.contact-input:focus {
    outline: none;
    text-decoration: none;
    -webkit-appearance: none;
}

.contact-finish-buttons {
    display: flex;
    width: 100%;
    height: 25px;

    justify-content: space-between;
    align-items: center;

    gap: 5px;

    margin-top: 10px;


}

.contact-save-button, .contact-cancel-button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    background-color: rgb(3, 163, 30);
    box-shadow: inset 0 0 5px 0px rgba(94, 94, 94, 0.644);
    border-radius: 0.25vh;

    color: white;
}

.contact-cancel-button {
    background-color: rgb(236, 236, 236);
    color: rgba(0, 0, 0, 0.747);
}

.contact-save-button:hover, .contact-cancel-button:hover {
    cursor: pointer;
    opacity: 0.9;
}

.contact-delete-button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    background-color: rgb(109, 9, 9);
    box-shadow: inset 0 0 5px 0px rgba(94, 94, 94, 0.644);
    border-radius: 0.25vh;

    color: white;

    margin-top: 10px;
    margin-bottom: 15px;
}

.contact-delete-button:hover {
    cursor: pointer;
    opacity: 0.9;

}