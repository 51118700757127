@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root {
    --main-color: #0088ff;
    --secondary-color: rgb(134 147 151);
}

::-webkit-scrollbar {
    width: 10px;
    background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
    background: var(--main-color);
    background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-track {
    background: var(--secondary-color);
    background-color: rgba(0, 0, 0, 0);
}




.homepage-wrapper {
    display: flex;
    flex-direction: row;

    font-family: 'Poppins', sans-serif;
    

    width: 100vw;
    height: 100vh;

    background: url('https://i.imgur.com/1Hiqu5M.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    backdrop-filter: blur(5px);

}

.homepage-sidebar-wrapper {
    display: flex;
    flex-direction: column;

    align-items: center;
    
    align-self: center;

    width: 20%;
    height: 90%;

    
    border-right: 2px solid white;

    padding: 15px;

    color: rgba(255, 255, 255, 0.815);
    text-shadow: 0px 0px 2.5px rgba(255, 255, 255, 0.815);

}

.homepage-content-wrapper {
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 80%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.685);
    backdrop-filter: blur(5px);
}

.preview-options-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;


    width: 100%;
    min-height: 80%;
    max-height: 90%;

    border: 1px solid rgba(0, 47, 255, 0);

    overflow-y: scroll;

    padding-top: 25px;
}

.preview-button {
    display: flex;
    position: relative;

    justify-content: center;
    align-items: center;

    width: 100%;
    height: 150px;

    margin-bottom: 15px;

    font-size: 25px;
    font-weight: 600;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.685);

    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.486);
    box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.788);

    transition: all 0.1s ease;
}

.preview-button:hover {
    /* transform: rotate(45deg); */
    scale: 0.75;
    cursor: pointer;
}