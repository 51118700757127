#camera-app {
    display: flex;

    flex-direction: column;

    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, rgb(83, 82, 82) 0%, rgb(10, 110, 105) 60%);


    padding: 100px 15px;

    gap: 15px;
}

.camera-option {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    
    padding: 15px;

    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0.5vh;

    background: rgba(0, 0, 0, 0.61);
}

.camera-option:hover {
    background: rgba(0, 0, 0, 0.8);
    cursor: pointer;
}

.cam-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 90%;

    
    color: rgba(255, 255, 255, 0.63);

    font-size: 10px;
    margin-top: 15px;
}

.cam-info-item {
    display: flex;
    
    justify-content: space-between;
    align-items: center;

    width: 100%;

    padding: 5px;

    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 5px;
}

.camera-key {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 5px;


    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0.5vh;

    background: rgba(0, 0, 0, 0.61);
}
.gallery-wrapper {
    display: flex;
    flex-direction: column;

    position: absolute;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    background: rgb(46, 46, 46);

    padding-top: 55px;
    padding-bottom: 35px;
}

.gallery-header {
    display: flex;
    position: relative;

    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: auto;
    max-height: 50px;

    
    padding: 15px;

    background: rgba(0, 0, 0, 0.678);
}

.gallery-items-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(70px, 1fr));

    justify-content: center;
    align-items: center;

    padding: 5px;

    width: 100%;
    height: 100%;

    overflow-y: scroll;
}

.gallery-item {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    border: 1px solid rgba(0, 0, 0, 0.712);
}

.gallery-item:hover {
    cursor: pointer;
    opacity: 0.8;
}

.gallery-close:hover {
    cursor: pointer;
    color: red;
}

.image-preview {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.76);

    width: 100%;
    height: 100%;

    padding: 1px;

    z-index: 9999;
}