#garage-app {
    display: flex;
    flex-direction: column;


    background: linear-gradient(-45deg, rgb(0, 132, 255) 0%, var(--garage) 60%);
}

.garage-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(29, 29, 29);
    width: 100%;

    padding: 15px;
}

.vehicles-wrapper {
    display: flex;
    flex-direction: column;
    
    align-items: center;

    background-color: rgba(0, 0, 0, 0.589);

    width: 100%;
    height: 100%;
    padding: 5px;

    overflow-y: auto;
}

.vehicle-item-wrapper {
    display: flex;
    flex-direction: column;

    justify-content: space-between;

    width: 100%;
    margin-bottom: 10px;

    background-color: rgba(0, 0, 0, 0.589);
    border: 2px solid rgba(255, 255, 255, 0.445);
    box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.377);
    border-radius: 0.25vh;
}

.vehicle-item-upper {
    display: flex;
    justify-content: space-between;
    align-items: center;


    border-bottom: 1px solid rgba(255, 255, 255, 0.295);

    padding: 10px;

}

.vehicle-item-mid {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 12.5px;

    border-bottom: 1px solid rgba(255, 255, 255, 0.295);
    background-color: rgba(0, 0, 0, 0.589);

    padding: 10px;

}

.vehicle-item-lower {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;

    padding: 10px;

    gap: 5px;
}

.vehicle-plate {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid rgba(255, 255, 255, 0.438);
    padding: 5px;

}

.vehicle-stat {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid rgba(255, 255, 255, 0.438);

    width: 100%;
    padding: 5px;
}
