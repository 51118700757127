@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

#groups-app {
    background: linear-gradient(
        -45deg,
        #000000,
        #797979
      ); /* Gradient background */
}


.jobs-container {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.096);

    overflow-y: scroll;
}

.jobs-title, .groups-title {
    display: flex;

    width: 100%;
    height: auto;

    justify-content: center;
    align-items: center;

    padding-top: 15px;
    padding-bottom: 15px;

    font-size: 17.5px;
    font-weight: bold;

    background-color: rgba(0, 0, 0, 0.616);
    box-shadow: inset 0 0 35px 0px rgba(0, 0, 0, 0.63);
    z-index: 1;

}
  


.groups-title {
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 15px;

}

.groups-back-button {
    margin-right: 15px;
}

.groups-back-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.groups-create {
    display: flex;

    height: 70%;

    justify-content: center;
    align-items: center;

    font-size: 12.5px;
    padding: 10px;
    border-radius: 0.5vh;
    margin-left: 15px;
    background-color: rgba(3, 196, 3, 0.61);
    box-shadow: inset 0 0 15px 0px rgba(83, 83, 83, 0.418);
}

.groups-create:hover {
    opacity: 0.9;

    cursor: pointer;
}

.jobs-list {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-start;

    position: relative;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.384);

    padding-top: 10px;
    padding-bottom: 10px;

    overflow-y: auto;
    max-height: 80vh;
}

.jobs-item, .group-item {
    display: flex;

    width: 90%;
    height: auto;

    justify-content: space-between;
    align-items: center;

    font-size: 15px;

    margin-top: 10px;

    padding: 15px;
    padding-left: 25px;
    padding-right: 25px;

    background-color: rgba(255, 255, 255, 0.459);
    border: 2px solid rgba(255, 255, 255, 0.432);
    box-shadow: inset 0 0 35px 0px rgba(0, 0, 0, 0.185);
    border-radius: 0.25vh;
}

.jobs-item {
    box-shadow: inset 0 0 150px 10px rgba(0, 0, 0, 0.678);
    min-height: 100px;

    text-transform: uppercase;

    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 2px;
    font-style: italic;

    color: rgba(255, 255, 255, 0.856);

    background: red;

    font-size: 20px;
    font-weight: bold;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.863);

}

.job-button {
    display: flex;

    justify-content: center;
    align-items: center;

    width: 50px;
    height: 50px;
    padding: 10px;
    padding-left: 10px;
    padding-right: 10px;

    font-size: 15px;

    border: 1px solid rgba(255, 255, 255, 0.87);
    background-color: rgba(0, 0, 0, 0.842);
    text-shadow: 0 0 2.5px rgba(0, 0, 0, 0.541);

    border-radius: 50%;

}

.job-button:hover {
    opacity: 0.9;
    scale: 1.05;
    background-color: rgba(185, 185, 185, 0.356);

    cursor: pointer;
}

.group-item:hover {
    opacity: 0.9;
    cursor: pointer;
}

.mygroup {
    display: flex;
    flex-direction: column;
    
    align-self: center;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.096);

    overflow-y: scroll;
}

.mygroup-title {
    display: flex;

    width: 100%;
    height: auto;

    justify-content: center;
    align-items: center;

    padding: 15px;

    font-size: 15px;

    background-color: rgba(0, 0, 0, 0.685);

    justify-content: space-between;
}

.mygroup-members {
    display: flex;
    flex-direction: column;

    width: 100%;
    align-items: center;

    overflow-y: auto;

}

.mygroup-item {
    display: flex;
    flex-direction: column;
    width: 100%;

    align-items: center;

}

.member-item {
    display: flex;

    width: 95%;
    height: auto;

    justify-content: space-between;
    align-items: center;

    font-size: 15px;

    margin-top: 10px;
    padding: 15px;
    padding-left: 25px;
    padding-right: 25px;

    background-color: rgba(0, 0, 0, 0.438);
    box-shadow: 0 0 35px 0px rgba(75, 75, 75, 0.295);
    border-radius: 0.5vh;
}

.member-remove {
    font-size: 20px;
}

.member-remove:hover {
    color: rgba(255, 0, 0, 0.678);
    text-shadow: none;
    cursor: pointer;
}

.mygroup-disband {
    display: flex;

    height: 70%;

    justify-content: center;
    align-items: center;

    font-size: 12.5px;
    padding: 10px;
    border-radius: 0.5vh;
    margin-left: 15px;
    background-color: rgba(196, 3, 3, 0.61);
    box-shadow: inset 0 0 15px 0px rgba(83, 83, 83, 0.418);
}

.mygroup-disband:hover {
    opacity: 0.9;
    cursor: pointer;
}

.groups-list {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: flex-start;

    position: relative;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.384);

    padding: 10px;

    overflow-y: auto;
    max-height: 80vh;
}

.job-requirement {
    display: flex;

    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 100%;
    padding: 15px;
    margin-bottom: 5px;
    border-radius: 0.5vh;

    border: 1px solid rgba(255, 255, 255, 0.397);

    
    font-size: 15px;

    background-color: rgba(0, 0, 0, 0.596);
}

.selected-job-image {
    display: flex;

    justify-content: center;
    align-items: center;
    height: 100%;

    padding: 5px;
    border-radius: 0.5vh;

    margin-bottom: 5px;

    background-color: rgba(255, 255, 255, 0.596);

}

#locations {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    height: auto;

    padding: 15px;
    margin-top: 10px;

    background-color: rgba(0, 0, 0, 0.596);
    border-radius: 0.5vh;
}

.locations-title {
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.226);
}

.location-select {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 0.5vh;

    font-size: 15px;

    color: white;

    background-color: rgba(0, 0, 0, 0.596);
}

.group-invite {
    display: flex;

    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 25px;

    background-color: rgba(0, 0, 0, 0.685);

    border-top: 2px solid rgba(255, 255, 255, 0.87);

    overflow-y: scroll;
}

.group-invite-input {
    width: 100%;

    padding: 5px;
    margin-right: 10px;

    font-size: 15px;

    background-color: rgba(255, 255, 255, 0.459);
    border: 1px solid rgba(255, 255, 255, 0.459);

    color: white;
    border-radius: 0.5vh;
}

.group-invite-input:focus {
    outline: none;
}

.group-invite-input::placeholder {
    color: rgba(228, 228, 228, 0.726);
}

.group-invite-button {
    display: flex;

    justify-content: center;
    align-items: center;

    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;

    font-size: 15px;
    
    background-color: rgba(12, 182, 12, 0.842);
    text-shadow: 0 0 2.5px rgba(0, 0, 0, 0.541);

    border-radius: 0.5vh;
}

.group-invite-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.job-info-wrapper {
    display: flex;
    flex-direction: column;

    width: 100%;

    padding: 15px;

    background-color: rgba(216, 216, 216, 0.596);
    background: url('http://imageshack.com/a/img923/4918/tEV9GD.png');
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;

    box-shadow: inset 0 0 35px 0px rgba(0, 0, 0, 0.63);
}

.job-info-item {
    display: flex;

    width: 100%;

    justify-content: space-between;
    align-items: center;

    font-size: 15px;

    margin-top: 10px;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;

    background-color: rgba(0, 0, 0, 0.733);
    box-shadow: inset 0 0 15px 0px rgba(0, 0, 0, 0.63);
}

.start-job-button {
    display: flex;

    justify-content: center;
    align-items: center;

    width: 100%;
    margin-top: 10px;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;

    font-size: 15px;

    background-color: rgba(9, 138, 41, 0.842);
    text-shadow: 0 0 2.5px rgba(0, 0, 0, 0.541);

    border-radius: 0.5vh;
}

.start-job-button:hover {
    opacity: 0.9;
    cursor: pointer;
}