#settings-app {
    display: flex;
    flex-direction: column;

    align-items: center;

    background: linear-gradient(-45deg, rgb(26, 26, 26) 0%, rgb(80, 80, 80) 60%);
    padding: 15px;
}

.settings-title {
    margin-top: 40px;
    margin-bottom: 15px;

    font-size: 25px;
}

.wallpaper-container {
    display: flex;
    flex-direction: column;


    align-items: center;

    width: 100%;
    height: auto;

    padding: 10px;
    margin: 5px;
    margin-top: 15px;

    font-size: 15px;

    border-radius: 0.5vh;

    background: rgba(0, 0, 0, 0.596);


}

.wallpaper-wrapper {
    display: flex;

    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 50px;

    margin-top: 5px;

    background-color: rgba(0, 255, 34, 0);

}

.wallpaper-title {
    text-shadow: 0 0 2.5px rgb(255, 255, 255);

}

.custom-title {
    display: flex;

    justify-content: center;
    align-items: center;

    background-color: rgba(255, 0, 0, 0);

    width: 50%;

    font-size: 15px;
}

.wallpaper-input {
    display: flex;

    width: 100%;

    background-color: rgba(0, 0, 0, 0.596);

    border: none;
    border-radius: 0.5vh;

    padding: 5px;
    font-size: 15px;

    color: white;
}

.wallpaper-submit {
    display: flex;

    justify-content: center;
    align-items: center;

    width: 50%;

    background-color: rgba(10, 154, 221, 0.596);

    border: none;
    border-radius: 0.5vh;

    padding: 5px;
    font-size: 15px;

    color: white;

}

.wallpaper-submit:hover {
    cursor: pointer;
    opacity: 0.8;
}

.presets-title {
    margin-top: 20px;
    margin-bottom: 5px;

    text-shadow: 0 0 2.5px rgb(255, 255, 255);
}

.presets-wrapper {
    display: flex;

    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: auto;

    margin: 5px;

    background-color: rgba(0, 128, 0, 0);
}

.preset {
    display: flex;

    width: 30%;
    height: 100%;
}

.preset:hover {
    cursor: pointer;
    scale: 1.05;
    opacity: 0.8;

    border: 2px solid white;
}