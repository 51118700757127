@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --main-color: rgba(3, 154, 255, 0.747);
    --secondary-color: rgb(134 147 151);

    --mail : rgb(5, 151, 209);
    --garage : rgb(102, 71, 216);
    --ping : rgb(143, 16, 16);
    --gang : rgb(17, 69, 146);
    --calculator : rgba(240, 147, 7, 0.795);
    --notes : rgba(223, 196, 43, 0.897);
    --twatter : rgb(10, 149, 204);
    --wallet : rgb(17, 80, 21);
    --settings : rgba(83, 83, 83, 0.959);
    --info : rgb(6, 175, 105);

    --contacts : rgb(202, 156, 7);
    --phone : rgb(14, 163, 39);
    --messages : rgb(0, 204, 102);
    --camera : rgba(97, 97, 97, 0.925);

    --groups : rgb(20, 20, 20);


    --frame-color: rgb(173, 173, 173);


    --phone-width: 285px;
    --phone-height: 610.25px;
}

body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    

    background: url('http://imageshack.com/a/img923/7485/1urIlb.png') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;

    /* backdrop-filter: blur(15px); */

    background: none !important; 
    overflow: hidden;
    scale: 1;

    display: flex;

    user-select: none;


    justify-content: flex-end !important;
    align-items: flex-end !important;

    color: white;
}

.phone-info-text {
  display: flex;
  
  text-align: center;

  align-items: center;
  justify-content: center;


  font-size: 15px;
}

.phone-test-button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: auto;

  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 5px 15px;

  margin-top: 15px;
  margin-bottom: 25px;
}
.phone-test-button:hover {
  cursor: pointer;
  opacity: 0.75;
}

.main {
  display: flex;
  flex-direction: column;
  position: relative;

  justify-content: center;
  align-items: center;



  background-color: rgba(11, 202, 68, 0);

  width: 100%;
  height: 100%;


  scale: 1;

  transition: all 0.5s ease-in-out;
}

@keyframes PhoneNotification {
  0% {
    bottom: calc((var(--phone-height) / 0.75)*-1);
  }
  100% {
    bottom: calc((var(--phone-height) / 1.25)*-1);
  }
}

@keyframes PhoneOpen {
  0% {
    bottom: calc((var(--phone-height) / 0.75)*-1);
  }
  100% {
    bottom: 5px;
  }
}

@keyframes PhoneClose {
  0% {
    bottom: 0px;
  }
  100% {
    bottom: calc((var(--phone-height) / 0.75)*-1);
  }
}




.phone-frame {
  display: flex;
  position: relative;

  flex-direction: column;

  justify-content: center;
  align-items: center;


  border-radius: 40px;

  width: var(--phone-width);
  height: var(--phone-height);
  box-shadow: 0 0 10px 0 rgb(0, 0, 0);

  /* background: url('https://i.imgur.com/vKt3vbM.png') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;  */

}

.phone-container {
  display: flex;
  flex-direction: column;

  align-items: center;

  background: url('http://imageshack.com/a/img923/7485/1urIlb.png');
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;

  box-shadow: 0 0 10px 0 rgb(0, 0, 0);
  box-shadow: inset 0 0 100px 0px rgba(0, 0, 0, 0.705);

  width: 100%;
  height: 100%;

  border-radius: 40px;



  border: 2px solid var(--frame-color);
  border-top-width: 5px;
  border-bottom-width: 5px;
}

.notifications-container{
  display: flex;
  position: absolute;
  flex-direction: column-reverse;

  align-items: center;
  justify-content: flex-end;

  width: var(--phone-width);
  height: var(--phone-height);

  padding-top: 10px;
  padding-bottom: 100px;

  border-top-left-radius: 60px;
  border-top-right-radius: 60px;

  overflow-y: scroll;

  pointer-events: none;

  z-index: 10000 !important;

}

.phone-notification {
  display: flex;
  position: relative;

  align-items: center;
  justify-content: space-between;

  width: 90%;
  height: auto;

  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;

  top: 5px;

  margin-bottom: 5px;

  border-radius: 1.5vh;

  color: white;

  background: linear-gradient(90deg, rgb(0, 0, 0), rgb(26, 26, 26) 25%, rgb(26, 26, 26) 75%, rgb(0, 0, 0) 100%);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.678);
  
  border: 2px solid rgba(255, 255, 255, 0);

  font-size: 15px;

  pointer-events: all;
  z-index: 1;

  animation: FadeIn 0.75s;

}

@keyframes FadeIn {
  0% {
    scale: 0;
    opacity: 0;
  }
  100% {
    scale: 1;
    opacity: 1;
  }
}

.notification-icon {
  display: flex;

  align-items: center;
  justify-content: center;

  width: 15%;
  height: 100%;

  font-size: 20px;

}

.notification-message {
  display: flex;
  flex-direction: column;

  width: 65%;
  height: 100%;

  align-items: flex-start;
  justify-content: center;
  

  padding-left: 0px;

  color: rgba(255, 255, 255, 0.575);

  font-size: 10px;
}

.notification-title {
  color: white;
  font-size: 12.5px;
}

.notification-yes, .notification-no {
  font-size: 15px;
}

.notification-yes:hover, .notification-no:hover {
  opacity: 0.5;
  cursor: pointer;
}

.alert {
  position: absolute;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-between;

  width: 90%;
  height: auto;

  padding: 15px;

  padding-top: 15px;
  padding-bottom: 15px;

  top: 35%;

  color: rgba(0, 0, 0, 0.76);

  border-radius: 1.5vh;

  border: 2px solid rgba(255, 255, 255, 0.932);

  background: rgb(255, 255, 255);
  box-shadow: inset 0 0 15px 0px rgba(0, 0, 0, 0.425);
  z-index: 1;
}

.alert-title {

  font-size: 1.75vh;
  margin-bottom: 15px;

  font-weight: bold;
}

.alert-input {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: auto;

  background-color: rgba(0, 0, 0, 0.596);

  border: none;
  border-radius: 0.5vh;

  padding: 10px;
  font-size: 1.5vh;

  color: white;



  margin-bottom: 15px;
}

.alert-input::placeholder {
  color: rgba(255, 255, 255, 0.685);
}

.alert-input:focus {
  outline: none;
}

.alert-buttons {
  display: flex;
  justify-content: center;

  margin: 5px;

  width: 100%;
}

.alert-button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 42.5%;
  height: auto;

  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;

  color: white;

  font-size: 1.5vh;

  border-radius: 0.5vh;

  margin: 5px;
}

.alert-button:hover {
  cursor: pointer;
  opacity: 0.9;
}

.phone-time {
  position: absolute;
  top: 25px;
  left: 35px;

  font-size: 13.5px;
  z-index: 1;
}

.phone-wifi {
  position: absolute;
  top: 25px;

  font-size: 12.5px;


  right: 65px;
  z-index: 1;

}

.phone-battery {
  position: absolute;
  top: 25px;
  font-size: 12.5px;

  right: 35px;
  z-index: 1;

}

.home-button {
  display: flex;
  position: absolute;
  
  bottom: 20px;

  left: 32%;

  justify-self: center;
  align-self: center;

  width: 35%;
  height: 3.5px;

  background-color: white;
  border-radius: 5px;
  z-index: 1;

}

.home-button:hover {
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  scale: 1.05;
}

.phone-content {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  padding-top: 40px;
  padding-bottom: 40px;


  color: white;
}


.home-header {
  width: 100%;
  height: 20%;

  display: flex;

  justify-content: center;
  align-items: center;

  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 55px;

  color: rgba(255, 255, 255, 0.89);
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.808);

}

.home-top {
  display: grid;

  /* 4 columns 5 rows */
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;

  justify-content: center;
  align-items: center;

  padding-top: 15px;
  padding-left: 15px;

  width: 90%;
  height: 85%;
  color: white;
}

.home-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;;
  width: 90%;
  height: 15%;
  color: white;
  border-bottom: 2px solid rgba(255, 255, 255, 0.582);
  margin-bottom: 5px;
}

.app {
  display: flex;

  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;

  font-size: 22.5px;
  border-radius: 1vh;
  background-color: rgba(0, 0, 0, 0.603);
  box-shadow: inset 0 0 15px 0px rgba(0, 0, 0, 0.425);

  transition: all 0.15s ease-in-out;
}

.app:hover {
  cursor: pointer;
  opacity: 0.85;
  scale: 1.05;
}

.app-container {
  display: flex;
  position: absolute;
  flex-direction: column;

  align-items: center;

  padding-top: 50px;
  padding-bottom: 40px;

  background: transparent;


  width: 100%;
  height: 100%;

  border-radius: 40px;


  border: 2px solid var(--frame-color);
  border-top-width: 5px;
  border-bottom-width: 5px;

  animation: AppOpen 0.15s ease-in-out;

  overflow: hidden;

  user-select:text;
}

/* .app-container::before {
  content: '';
  margin: -35px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border-radius: 60px;

  background: rgba(0, 0, 0, 0);

  backdrop-filter: blur(15px);

  z-index: -1;
  pointer-events: none;
} */

@keyframes AppOpen {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

::-webkit-scrollbar {
  width: 0px;
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 0, 0, 0);
  border-radius: 0px;
}



