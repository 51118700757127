
#wallet-app {
    
    
    background: linear-gradient(180deg, rgb(3, 168, 31) 0%, rgb(2, 158, 132) 100%);
    box-shadow: inset 0 0 125px 5px rgba(54, 54, 54, 0.733);
    color: white;
    width: 100%;
    height: 100%;

    --s: 37px; /* control the size */

    --c: #0000, #282828 0.5deg 119.5deg, #0000 120deg;
    --g1: conic-gradient(from 60deg at 56.25% calc(425% / 6), var(--c));
    --g2: conic-gradient(from 180deg at 43.75% calc(425% / 6), var(--c));
    --g3: conic-gradient(from -60deg at 50% calc(175% / 12), var(--c));
    background: var(--g1), var(--g1) var(--s) calc(1.73 * var(--s)), var(--g2),
      var(--g2) var(--s) calc(1.73 * var(--s)), var(--g3) var(--s) 0,
      var(--g3) 0 calc(1.73 * var(--s)) #1e1e1e;
    background-size: calc(2 * var(--s)) calc(3.46 * var(--s));
}

.wallet-header {
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    height: 15%;
    
    background-color: rgba(34, 34, 34, 0.842);
    box-shadow: inset 0 0 25px 5px rgba(0, 0, 0, 0.733);
    color: rgba(4, 151, 36, 0.808);
    text-shadow: 0 0 2.5px rgba(4, 151, 36, 0.808);

    font-size: 20px;

    margin-bottom: 15px;
}

.wallet-actions {
    display: flex;
    
    justify-content: center;
    align-items: center;

    height: 55px;
    width: 100%;

    padding: 15px;
    padding-bottom: 5px;
    gap: 5px;

    background-color: rgba(0, 0, 0, 0);
}

.wallet-action {
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 100%;
    height: 100%;
    max-height: 40px;

    padding: 10px;

    color: rgba(255, 255, 255, 0.904);
    
    background-color: rgba(0, 0, 0, 0.726);
    border: 1px solid rgba(255, 255, 255, 0.247);
    border-radius: 0.5vh;
    color: white;

    margin-bottom: 12.5px;
    font-size: 15px;
}

#invoice-button {
    width: 90%;
}

.wallet-action:hover {
    opacity: 0.9;
    background-color: rgba(0, 0, 0, 0.836);
    color: rgba(9, 199, 34, 0.911);

    font-weight: bold;

    cursor: pointer;
}

.wallet-accounts {
    display: flex;
    flex-direction: column;

    align-items: center;

    width: 100%;
    height: 80%;

    padding-top: 15px;

    background-color: rgba(0, 0, 0, 0.247);
    box-shadow: inset 0 0 25px 5px rgba(0, 0, 0, 0.733);

    overflow-y: scroll;
}

.wallet-account-container {
    display: flex;

    justify-content: space-between;
    align-items: flex-end;
    padding: 15px;
    padding-left: 10px;

    width: 95%;
    height: auto;
    font-size: 12.5px;

    background-color: rgba(32, 32, 32, 0.966);
    box-shadow: inset 0 0 25px 5px rgba(0, 0, 0, 0.884);
    border: 1px solid rgba(255, 255, 255, 0.459);

    margin-bottom: 5px;
    border-radius: 0.5vh;

    color: rgba(187, 187, 187, 0.986);
}

.wallet-account-name {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.877);
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.651);
    align-self: flex-start;
    margin-bottom: 10px;

    border-bottom: 1px solid rgba(255, 255, 255, 0.308);
}

.wallet-account-balance {
    display: flex;
    flex-direction: column;
    font-size: 17.5px;
    color: rgba(12, 177, 6, 0.911);

    padding-top: 15px;

    text-align: right;
    font-weight: 900 !important;
    align-self: flex-end;
}

.available-balance-text {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.596);
    text-align: right;

}

.invoice-wrapper {
    display: flex;
    flex-direction: column;

    position: absolute;

    align-items: center;

    background-color: rgba(0, 0, 0, 0.952);

    width: 100%;
    height: 85%; 

    padding: 5px;

    z-index: 999;
}

.create-invoice-button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: auto;

    padding: 5px;

    background-color: rgba(12, 177, 6, 0.911);
    box-shadow: inset 0 0 25px 5px rgba(0, 0, 0, 0.733);
    border: 1px solid rgba(255, 255, 255, 0.247);

    color: rgba(255, 255, 255, 0.911);
    font-size: 15px;


}

.create-invoice-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.invoice-close:hover {
    scale: 1.05;
    opacity: 0.9;
    cursor: pointer;
    color: red;
    text-shadow: none;

}

.invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;

    background-color: rgba(34, 34, 34, 0.842);
    box-shadow: inset 0 0 25px 5px rgba(0, 0, 0, 0.733);
    color: rgba(255, 255, 255, 0.808);
    text-shadow: 0 0 2.5px rgba(255, 255, 255, 0.651);

    font-size: 15px;

    margin-bottom: 15px;
}

.invoice-list {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 80%;

    padding: 5px;

    background-color: rgba(255, 255, 255, 0.253);
    box-shadow: inset 0 0 25px 5px rgba(0, 0, 0, 0.267);

    margin-bottom: 5px;

    overflow-y: scroll;
}

.invoice-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: auto;

    padding: 15px;
    padding-left: 10px;
    padding-right: 10px;

    background-color: rgba(0, 0, 0, 0.699);
    box-shadow: inset 0 0 25px 5px rgba(0, 0, 0, 0.267);

    margin-bottom: 5px;
    border-radius: 0.5vh;

    color: rgba(187, 187, 187, 0.986);
}

.invoice-info {
    display: flex;
    color: white;
    font-size: 15px;

    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.invoice-date {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.596);
}

.invoice-amount {
    color: rgba(17, 155, 17, 0.74);
    text-shadow: 0px 0px 1.5px green;
    width: 25%;

    margin-right: 15px;
    text-align: left;
    align-items: flex-start;

    background-color: rgba(0, 217, 255, 0);
}

.invoice-status-pay-button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 75px;
    height: auto;

    padding: 1.5px;
    padding-left: 10px;
    padding-right: 10px;

    background-color: rgba(255, 255, 255, 0.171);
    box-shadow: inset 0 0 25px 5px rgba(0, 0, 0, 0.733);
    border: 1px solid rgba(255, 255, 255, 0.247);

    border-radius: 0.25vh;

    color: rgba(255, 255, 255, 0.911);
    font-size: 15px;

    margin-top: 5px;
}

.invoice-status-pay-button:hover {
    background-color: rgba(17, 155, 17, 0.986);
    cursor: pointer;
}

.invoice-status-paid {
    display: flex;
    justify-content: center;
    align-items: center;

    width: auto;
    height: auto;

    padding: 2.5px;
    padding-left: 10px;
    padding-right: 10px;

    background-color: rgba(255, 255, 255, 0);

    border-radius: 0.5vh;

    color: rgba(17, 155, 17, 0.986);
    font-size: 15px;


}

.invoice-sent-status-paid {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 50px;
    height: auto;

    padding: 2.5px;
    padding-left: 10px;
    padding-right: 10px;


    border-radius: 0.5vh;

    color: rgba(255, 255, 255, 0.911);
    font-size: 15px;
}

.create-invoice-wrapper {
    display: flex;
    flex-direction: column;

    position: absolute;

    justify-content: center;
    align-items: center;

    width: 80%;
    height: auto;

    top: 30%;

    padding: 15px;

    background-color: rgba(151, 151, 151, 0.925);
    border: 2px solid rgba(255, 255, 255, 0.247);
    box-shadow: inset 0 0 25px 5px rgba(0, 0, 0, 0.733);
    border-radius: 0.5vh;

    z-index: 1000;
}

.create-invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;

    background-color: rgba(34, 34, 34, 0.842);
    box-shadow: inset 0 0 25px 5px rgba(0, 0, 0, 0.733);
    color: rgba(255, 255, 255, 0.808);
    text-shadow: 0 0 2.5px rgba(255, 255, 255, 0.651);

    font-size: 15px;

    margin-bottom: 15px;
}

.create-invoice-close:hover {
    scale: 1.05;
    opacity: 0.9;
    cursor: pointer;
    color: red;
    text-shadow: none;

}

.create-invoice-form {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: auto;

    justify-content: center;
    align-items: center;
}

.invoice-input-wrapper {
    display: flex;

    width: 100%;
    height: auto;

    justify-content: space-between;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.699);
    border: 1px solid rgba(255, 255, 255, 0.247);
    border-radius: 0.5vh;

    margin-bottom: 5px;

    padding: 5px;
    padding-left: 10px;

    font-size: 15px;
}

.invoice-input {
    display: flex;

    width: 50%;
    height: auto;

    justify-content: center;
    align-items: center;

    text-align: center;

    padding: 5px;

    background-color: rgba(0, 0, 0, 0.699);
    border: none;
    border-radius: 0.5vh;

    color: rgba(255, 255, 255, 0.986);
}

.invoice-input::placeholder {
    color: rgba(187, 187, 187, 0.986);
}

.invoice-input:focus {
    outline: none;
}

.send-invoice-button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: auto;

    padding: 5px;

    background-color: rgba(12, 177, 6, 0.911);
    box-shadow: inset 0 0 25px 5px rgba(0, 0, 0, 0.733);
    border: 1px solid rgba(255, 255, 255, 0.247);

    color: rgba(255, 255, 255, 0.911);
    font-size: 15px;

    margin-top: 15px;
}

.send-invoice-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.send-payment-wrapper, .request-payment-wrapper {
    display: flex;
    flex-direction: column;

    position: absolute;

    justify-content: center;
    align-items: center;

    width: 80%;
    height: auto;

    top: 30%;

    padding: 15px;

    background-color: rgba(151, 151, 151, 0.925);
    border: 2px solid rgba(255, 255, 255, 0.247);
    box-shadow: inset 0 0 25px 5px rgba(0, 0, 0, 0.733);
    border-radius: 0.5vh;

    z-index: 1000;
}

.send-payment-input-wrapper, .request-payment-input-wrapper {
    display: flex;
    position: relative;

    width: 100%;
    height: auto;

    justify-content: space-between;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.699);
    border: 1px solid rgba(255, 255, 255, 0.247);
    border-radius: 0.5vh;

    margin-bottom: 5px;

    padding: 5px;
    padding-left: 10px;

    font-size: 15px;
}

.send-payment-header, .request-payment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;

    background-color: rgba(34, 34, 34, 0.842);
    box-shadow: inset 0 0 25px 5px rgba(0, 0, 0, 0.733);
    color: rgba(255, 255, 255, 0.808);
    text-shadow: 0 0 2.5px rgba(255, 255, 255, 0.651);

    font-size: 15px;

    margin-bottom: 15px;
}

.send-payment-input, .request-payment-input {
    display: flex;

    width: 50%;
    height: auto;

    justify-content: center;
    align-items: center;

    text-align: center;

    padding: 5px;

    background-color: rgba(0, 0, 0, 0.699);
    border: none;
    border-radius: 0.5vh;

    color: rgba(255, 255, 255, 0.986);
}

.send-payment-button, .request-payment-button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: auto;

    padding: 5px;

    background-color: rgba(12, 177, 6, 0.911);
    box-shadow: inset 0 0 25px 5px rgba(0, 0, 0, 0.733);
    border: 1px solid rgba(255, 255, 255, 0.247);

    color: rgba(255, 255, 255, 0.911);
    font-size: 15px;

    margin-top: 15px;
}

.send-payment-button:hover, .request-payment-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.send-payment-close:hover, .request-payment-close:hover {
    scale: 1.05;
    opacity: 0.9;
    cursor: pointer;
    color: red;
    text-shadow: none;

}